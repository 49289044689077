<template>
  <div class="pt-3">
    <!-- <Loader v-if="performingRequest" /> -->
    <!-- <div>
      <button class="btn btn__outlined" @click="listPackages()">listPackages</button>
    </div> -->
    <div class="dashboard__container--body">
      
      <div class="dashboard__container--body--col">
        <div>
          <h4>5 Star Background Checks:</h4>
          <div class="mb-3 mt-3">
            <label for="title">Title</label>
            <input type="text" v-model="newCheck.title"></input>
          </div>
          <div class="mb-3">
            <label for="description">Type</label>
            <input type="text" v-model="newCheck.type"></input>
          </div>
          <div class="mb-4">
            <label for="id">ID</label>
            <input type="text" v-model="newCheck.Id"></input>
          </div>
          <div class="mb-5">
            <button class="btn btn__primary" @click="addCheck()">
              Add New Background Check
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
          <div style="overflow:auto;" class="pt-3">
            <vue-good-table
                :columns="columns"
                :rows="backgroundCheckTypes"
                 styleClass="vgt-table condensed"
                :search-options="{
                  enabled: false,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'category'">
                  <button class="chipDark mr-2 mb-2" v-for="(item,  index) in props.row.category" :key="index">
                    {{item.title}}
                  </button>
                </span>
                <span v-else-if="props.column.field == 'delete'">
                  <button class="icon" v-tooltip="'delete'" @click.prevent="deleteCheck(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="dashboard__container--body--col">
        <div>
          <h4>5 Star Drug Checks:</h4>
          <div class="mb-3 mt-3">
            <label for="title">Title</label>
            <input type="text" v-model="newDrugCheck.title"></input>
          </div>
          <div class="mb-3">
            <label for="description">Type</label>
            <input type="text" v-model="newDrugCheck.type"></input>
          </div>
          <div class="mb-4">
            <label for="id">ID</label>
            <input type="text" v-model="newDrugCheck.Id"></input>
          </div>
          <div class="mb-5">
            <button class="btn btn__primary" @click="addDrugCheck()">
              Add New Drug Check
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
          <div style="overflow:auto;" class="pt-3">
            <vue-good-table
                :columns="columns"
                :rows="drugCheckTypes"
                 styleClass="vgt-table condensed"
                :search-options="{
                  enabled: false,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'category'">
                  <button class="chipDark mr-2 mb-2" v-for="(item,  index) in props.row.category" :key="index">
                    {{item.title}}
                  </button>
                </span>
                <span v-else-if="props.column.field == 'delete'">
                  <button class="icon" v-tooltip="'delete'" @click.prevent="deleteDrugCheck(props.row, props.index)">
                    <i class="fas fa-trash ml-2 mr-2"></i>
                  </button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import firebase from 'firebase/app';
const fb = require('../../firebaseConfig.js')

export default {
  name: 'manageChecks',
  data: () => ({
    newCheck: {},
    newDrugCheck: {},
    // categories: ['Facility Services', 'Food and Beverage', 'Guest Services', 'Office and Clerical', 'Retail', 'Trades', 'Warehouse'],
    performingRequest: false,
     performingRequest2: false,
    columns: [
      {
        label: 'Title',
        field: 'title',
        width: '200px',
        sortable: false
      },
      {
        label: 'Type',
        field: 'type',
        width: '100px',
        sortable: false
      },
      {
        label: 'ID',
        field: 'Id',
        width: '100px',
        sortable: false
      },
      {
        label: 'Delete',
        field: 'delete',
        width: '40px',
        sortable: false
      },
    ],
    // responses: ['Passed', 'Needs Review', 'Failed Do Not Hire', 'Duplicate'],
    // columns: [
    //   {
    //     label: 'Requested',
    //     field: 'created',
    //     width: '200px'
    //   },
    //   {
    //     label: 'Type',
    //     field: 'type',
    //   },
    //   {
    //     label: 'Status',
    //     field: 'status',
    //     width: '180px'
    //   },
    //   {
    //     label: 'User',
    //     field: 'user',
    //   },
    //   {
    //     label: 'Response',
    //     field: 'response',
    //     width: '200px'
    //   },
    //   {
    //     label: 'Submitted By',
    //     field: 'orderedBy',
    //     html: true
    //   }
    // ]
  }),
  created () {
    this.$store.dispatch("getBackgroundCheckTypes")
    this.$store.dispatch("getDrugCheckTypes")
    // this.$store.dispatch("updateEventJobNos")
    
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'backgroundCheckTypes', 'drugCheckTypes']),
  },
  components: {
    Loader,
  },
  methods: {
    listPackages() {
      const getListPackages = firebase.functions().httpsCallable('listPackages')
      getListPackages()
      .then(result => {
        if (result && result.data) {
          console.log(result.data)
        }
      })
    },
    deleteCheck(item, index) {
      console.log(item)
      let backgroundCheckTypes = this.backgroundCheckTypes
      backgroundCheckTypes.splice(index, 1)
      fb.backgroundCheckTypesCollection.doc(item.id).delete()
      // this.$store.dispatch("getGroupFromId", this.$route.params.id);
    },
    deleteDrugCheck(item, index) {
      console.log(item)
      let drugCheckTypes = this.drugCheckTypes
      drugCheckTypes.splice(index, 1)
      fb.drugCheckTypesCollection.doc(item.id).delete()
      // this.$store.dispatch("getGroupFromId", this.$route.params.id);
    },
    async addCheck() {
      this.performingRequest = true
      await fb.backgroundCheckTypesCollection.add(this.newCheck)
      .then(
        doc => {
          fb.backgroundCheckTypesCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.newCheck = {}
      this.performingRequest = false
    },
    async addDrugCheck() {
      this.performingRequest2 = true
      await fb.drugCheckTypesCollection.add(this.newDrugCheck)
      .then(
        doc => {
          fb.drugCheckTypesCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.newDrugCheck = {}
      this.performingRequest2 = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearBackgroundCheckTypes")
    this.$store.dispatch("clearDrugCheckTypes")
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.newCheck = null
    delete this.newCheck
    this.newDrugCheck = null
    delete this.newDrugCheck
    this.columns = null
    delete this.columns
  }
}
</script>